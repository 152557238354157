<template>
  <div class="app-layout">
    <!--<div v-if="barMessage" class="top-bar" id="AfalerTopBar">
      {{ barMessage }}
    </div>-->
    <header-bar
      ref="headerBar"
      @openMobileNav="openMobileNav"
      @openGlobalSearch="openGlobalSearch"
      @openCart="openCart"
    ></header-bar>
<!--    <nav-bar></nav-bar>-->
    <mobile-nav ref="mobileNav"></mobile-nav>
    <global-search ref="globalSearch"></global-search>
    <cart ref="cart" @change="cartChange"></cart>
    <main>
      <!--<keep-alive>-->
      <router-view />
      <!--</keep-alive>-->
    </main>
    <footer-bar></footer-bar>
    <div id="app_sidebar-wrap">
      <ul class="sidebar-classic-core-list">
        <li @click="back()" v-if="canBack">
          <img
            src="@/assets/icon/back.png"
            alt=""
            style="object-fit: cover; object-position: 50% 50%"
          />
        </li>
        <li>
          <a
            href="mailto:afalerservice@gmail.com"
            class="cursor-pointer text-underline"
            ><img
              src="@/assets/icon/youxiang.jpg"
              alt=""
              style="object-fit: cover; object-position: 50% 50%"
          /></a>
        </li>
        <li @click="whatsappShare" v-if="flag == 1">
          <img
            src="@/assets/icon/dianhua.jpg"
            alt=""
            style="object-fit: cover; object-position: 50% 50%"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import HeaderBar from './components/header-bar.vue'
import NavBar from './components/nav-bar.vue'
import MobileNav from './components/mobile-nav.vue'
import GlobalSearch from './components/global-search.vue'
import FooterBar from './components/footer-bar.vue'
import Cart from '@/components/Cart'
import service from '@/utils/request'
export default {
  name: 'Layout',
  components: {
    HeaderBar,
    NavBar,
    MobileNav,
    GlobalSearch,
    FooterBar,
    Cart,
  },
  computed: {
    flag() {
      //console.log(this.$store.state)
      return this.$store.state.user.whatsapp
    },
  },
  data() {
    return {
      barMessage: '',
      canBack: false,
    }
  },
  watch: {
    $route: {
      handler(to) {
        if ('Home' !== to.name) {
          this.canBack = true;
        } else this.canBack = false;
      },
    },
  },
  mounted() {
    this.getMessage()
    if (this.$route.query.fbclid) {
      localStorage.setItem('fbclid', this.$route.query.fbclid);
      //this.canBack = true;
    }
  },
  methods: {
    whatsappShare() {
      window.open(this.$baseUrl + '/open/waMe')
    },
    openMobileNav() {
      this.$refs.mobileNav.open()
    },
    openGlobalSearch() {
      this.$refs.globalSearch.open()
    },
    openCart() {
      this.$refs.cart.open()
    },
    cartChange() {
      this.$refs.headerBar.getCartCount()
    },
    getMessage() {
      service({
        url: '/open/home',
        method: 'get',
      }).then((res) => {
        this.barMessage = res.data.notice || null
        this.$store.dispatch('user/setWhatsApp', res.data.showWhatsapp || '')
        this.$store.commit('user/SET_allCurrencies', res.data.allCurrencies || '')
        this.initCurrency(res);
        localStorage.setItem('shippingFree', res.data.shippingFree || null)
        this.$store.commit('user/SET_shippingFree', res.data.shippingFree || null)
        if (res.data.clientId) {
          localStorage.setItem('clientId', res.data.clientId)
        }
        if (res.data.mode) {
          localStorage.setItem('mode', res.data.mode)
        }
        fbq('init', res.data.facebookCode || '');//第二个参数是像素编号我用*代替
        fbq('track', 'PageView');
      })
    },
    initCurrency(res) {
      let currencyCode = localStorage.getItem('currencyCode');
      let currencySymbol = localStorage.getItem('currencySymbol');
      this.$store.commit('user/SET_currencyCode', currencyCode || res.data.currencyCode || '')
      this.$store.commit('user/SET_currencySymbol', currencySymbol || res.data.currencySymbol || '')
    },
    back() {
      // if (!this.to) {
      //   this.$router.push('/home').catch(() => {})
      // } else {
        this.$router.go(-1)
      //}
    },
  },
}
</script>

<style lang="scss">
@media screen and (min-width: 576px) {
  .top-bar {
    z-index: 10000;
    text-align: center;
    background: #eb565f;
    padding: 12px 10px;
    color: #fff;
  }
}
@media screen and (max-width: 576px) {
  .top-bar {
    z-index: 10000;
    text-align: center;
    background: #eb565f;
    padding: 2px 10px;
    color: #fff;
  }
}
#app_sidebar-wrap {
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 50090;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 55px;
  .sidebar-classic-core-list {
    position: relative;
    max-height: 346px;
    margin-bottom: 12px;
    overflow: auto;
    li {
      width: 55px;
      height: 55px;
      margin-bottom: 18px;
      font-size: 28px;
      cursor: pointer;
    }
  }
}
.card-month {
  .el-date-picker__header {
    display: none !important;
  }
}
</style>
