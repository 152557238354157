<template>
  <div v-loading="loading">
    <!--轮播图-->
    <!-- <div @click="openMobileNav" class="menuBtn">
          <img src="@/assets/icon/meun.svg" alt=""  />
    </div> -->
    <mobile-nav ref="mobileNav"></mobile-nav>
    <section
      class="main__wrapper carousel__wrapper no-section-border"
      style="margin: 0 !important"
    >
      <el-carousel indicator-position="none" :height="carouselHeight">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <div class="carousel-item" style="height: 100%">
            <a
              v-if="item.url"
              :href="item.url"
              :target="
                item.url.indexOf('target=self') > -1 ? '_self' : '_blank'
              "
            >
              <img
                :src="item.img"
                class="d-block"
                width="100%"
                style="height: 100%; object-fit: cover"
              />
              <div class="carousel-caption d-none d-md-block" style="width: 50%">
                <h3 style="font-size: 36px" v-html="item.desc"></h3>
              </div>
            </a>
            <a v-else>
              <img
                :src="item.img"
                class="d-block"
                width="100%"
                style="height: 100%; object-fit: cover"
              />
              <div class="carousel-caption d-none d-md-block" style="width: 50%">
                <h3 style="font-size: 36px" v-html="item.desc"></h3>
              </div>
            </a>
          </div>
        </el-carousel-item>
      </el-carousel>
    </section>
    <!-- shop best sellers -->
    <section class="main__wrapper no-section-border best_seller__wrapper">
      <div v-for="item in Category4" :key="item.id">
        <div class="section-header">
          <h2 class="placeholder-glow">
            <span class="placeholder col-3">{{ item.name }}</span>
          </h2>
        </div>
        <div class="section-body">
          <div class="" style="margin: 0 10vw">
            <el-row :gutter="1" style="display: flex; flex-wrap: wrap">
              <el-col
                :span="6"
                :sm="6"
                :xs="12"
                v-for="item in item.children"
                :key="item.id"
              >
                <div
                  class="section-box-s"
                  @click="viewDetail(item)"
                  href="javascript:void(0)"
                >
                  <img :src="item.img" />
                  <p>{{ item.name }}</p>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </section>

    <section class="main__wrapper no-section-border best_seller__wrapper">
      <MainProduct></MainProduct>
    </section>


    <section
      class="colletions_wrapper main__wrapper no-section-border best_seller__wrapper"
    >
      <el-row :gutter="5" style="display: flex; flex-wrap: wrap">
        <el-col
          :span="item.bigImg ? 24 : 12"
          :sm="item.bigImg ? 24 : 12"
          :xs="24"
          v-for="item in collectionsList"
          :key="item.id"
          :class="{ 'big-img' : item.bigImg }"
        >
          <div v-if="item.url" class="collections-box">
            <a :href="item.url" :target="item.url.indexOf('target=self') > -1 ? '_self' : '_blank'">
              <img v-if="item.bigImg" :src="item.bigImg" />
              <img v-else :src="item.img" />
              <p v-if="!item.bigImg">{{ item.name }}</p>
              <span v-if="!item.bigImg" style="width: 60%" v-html="item.intro"></span>
              <div v-if="!item.bigImg">shop now</div>
            </a>
          </div>
          <div v-else-if="item.productId" class="collections-box">
            <router-link
              tag="a"
              :to="{
                name: 'ProductDetail',
                query: {
                  type: 'collection',
                  productId: item.productId,
                  productName: item.productName,
                },
              }">
              <img v-if="item.bigImg" :src="item.bigImg" />
              <img v-else :src="item.img" />
              <p v-if="!item.bigImg">{{ item.name }}</p>
              <span v-if="!item.bigImg" style="width: 60%" v-html="item.intro"></span>
              <div v-if="!item.bigImg">shop now</div>
            </router-link>
          </div>
          <div v-else class="collections-box">
            <router-link
              tag="a"
              :to="{
                name: 'ProductList',
                query: {
                  type: 'collection',
                  collectionId: item.id,
                  collectionName: item.name,
                },
              }">
              <img v-if="item.bigImg" :src="item.bigImg" />
              <img v-else :src="item.img" />

              <p v-if="!item.bigImg">{{ item.name }}</p>
              <span v-if="!item.bigImg" style="width: 60%" v-html="item.intro"></span>
              <div v-if="!item.bigImg">shop now</div>
            </router-link>
          </div>
        </el-col>
      </el-row>
    </section>
    <!-- 评论列表 -->
    <section
      v-if="Array.isArray(commentList) && commentList.length > 0"
      class="main__wrapper comment__wrapper"
    >
      <div class="section-header">
        <h2>What Customers Say About us</h2>
      </div>
      <div class="section-body">
        <carousel :per-page="perpage">
          <slide v-for="item in commentList" :key="item.id">
            <div class="comment-item">
              <div class="comment-item-container">
                <div class="comment__heading">
                  <div class="comment_header">
                    <span class="comment_portfile">
                      <img
                        class="img-thumbnail"
                        :src="
                          item.memberHeaderImg ||
                          require('@/assets/icon/head.jpg')
                        "
                        alt="member profile"
                      />
                    </span>
                    <strong class="comment_user" :title="item.memberName">{{
                      item.memberName
                    }}</strong>
                  </div>
                  <div class="comment_time text-body-tertiary">
                    at {{ item.createTime }}
                  </div>
                  <star-rating
                    v-model="item.score"
                    :read-only="true"
                    :increment="0.5"
                    :show-rating="false"
                    :star-size="16"
                  ></star-rating>
                </div>
                <div class="comment_desc">
                  <vue-ellipsis
                    :visible-line="4"
                    ellipsis-node="..."
                    :text="item.content.replace(/\n/g, '<br>')"
                    :useInnerHtml="true"
                  >
                  </vue-ellipsis>
                </div>

                <div class="review-images thumbnails">
                  <div v-for="(image, index) in item.images"
                       :key="image.id"
                       :class="['thumbnail-image']"
                       @click="clickSmallImage(image, index, item.images)"
                  >
                    <img :src="image" />
                  </div>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </section>
    <!-- 热销商品 -->
    <section
      class="main__wrapper no-section-border best_seller__wrapper"
      v-show="topShow"
    >
      <div class="section-header">
        <h2 class="placeholder-glow">
          <span class="placeholder col-3">TOP PRODUCTS</span>
        </h2>
      </div>
      <div class="section-body">
        <div>
          <el-row
            :gutter="0"
            style="display: flex; flex-wrap: wrap;"
          >
            <el-col
              :span="6"
              :sm="6"
              :xs="12"
              v-for="item in hotProducts"
              :key="item.id"
              class="product-style"
            >
              <!-- v-quick-shop-hover -->
              <div class="card">
                <div class="product_intro">
                  <div class="product_tag" v-if="item.corner">
                    <span
                      class="sold_out"
                      :style="{ 'background-color': item.cornerColor }"
                      >{{ item.corner }}</span
                    >
                  </div>
                  <router-link
                    tag="a"
                    :to="{
                      name: 'ProductDetail',
                      query: {
                        type: 'collection',
                        productId: item.id,
                        productName: item.name,
                      },
                    }"
                  >
                    <!-- <img
                      class="lazy-collection-product card-img-top"
                      :src="item.coverImg"
                      height="344"
                      width="100%"
                    /> -->

                    <!--<div style="width: 100%; height:0px;padding-bottom: 100%;overflow:hidden;position: relative;">
                      <img
                        class="lazy-collection-product card-img-top"
                        :src="item.coverImg"
                        style="width: 100%; height:auto;left:0;top: 50%;transform: translateY(-50%);border:0px;position: absolute;"
                      >
                    </div>-->

                    <div v-if="item.imgs" style="width: 100%; height:0px;padding-bottom: 100%;overflow:hidden;position: relative;"  >
                      <ImgSwiper :data="item.imgs"></ImgSwiper>
                    </div>

                    <div v-else style="width: 100%; height:0px;padding-bottom: 100%;overflow:hidden;position: relative;">
                      <img
                        class="lazy-collection-product card-img-top"
                        :src="item.coverImg"
                        style="width: 100%; height:auto;left:0;top: 50%;transform: translateY(-50%);border:0px;position: absolute;"
                      >
                    </div>
                  </router-link>
                  <!--<div
                    :class="
                      item.oversold == 1 && item.totalCount == 0
                        ? 'soldOut'
                        : 'allowNone'
                    "
                  >
                    SOLD OUT
                  </div>-->
                  <!--<div class="shop_link" @click="quickShop(item.id, item)">
                    <span
                      :class="
                        item.oversold == 1 && item.totalCount == 0
                          ? 'noAllow'
                          : ''
                      "
                      >QUICK SHOP</span
                    >
                  </div>-->
                </div>
                <div
                  class="card-body pr"
                  style="padding-bottom: 10px; cursor: pointer;"
                  @click="viewDetail(item)"
                >
                  <p class="product-item__title" v-if="item.subName">
                    {{ item.subName + ' ' + item.name}}
                  </p>
                  <p class="product-item__title" v-else>{{ item.name }}</p>
                  <p class="product-item__price" v-if="item.originalPrice">
                    <span class="cur_price">
                      <CurrencySymbol :symbol="item.symbol" />{{ item.sellingPrice | moneyFormat }}
                    </span>
                    <span class="previous_price">
                      <CurrencySymbol :symbol="item.symbol" />{{ item.originalPrice | moneyFormat }}
                    </span>
                    <span
                      style="
                        display: inline;
                        opacity: 0.8;
                        font-size: 12px;
                        float: right;
                        line-height: 31px;
                        padding-right: 3px;
                        font-weight: 400;
                      "
                    >
                      save:{{
                        Math.round(
                          ([item.originalPrice - item.sellingPrice] /
                            item.originalPrice) *
                            100
                        )
                      }}%</span
                    >
                    <span v-if="item.score !== 0">
                      <div
                        style="
                          background: #fff;
                          height: 24px;
                          position: relative;
                        "
                      >
                        <el-rate
                          v-model="item.score"
                          disabled
                          text-color="#ff9900"
                        >
                        </el-rate>
                        <span
                          style="
                            position: absolute;
                            font-weight: 600;
                            font-size: 12px;
                            color: #ff9900;
                            left: 6.5rem;
                            top: 0.02rem;
                          "
                          v-show="item.reviewsNum"
                          >({{ item.reviewsNum }})</span
                        >
                      </div>
                    </span>
                    <span v-else>
                      <div style="background: #fff; height: 24px"></div>
                    </span>
                  </p>
                  <p class="product-item__price" v-else>
                    <!-- <span style="font-weight: 400; font-size: 20px; color: #de6d5c"> -->
                    <span class="cur_price">
                      <CurrencySymbol :symbol="item.symbol" />{{ item.sellingPrice | moneyFormat }}
                    </span>
                    <span v-if="item.score !== 0">
                      <div
                        style="
                          background: #fff;
                          height: 24px;
                          position: relative;
                        "
                      >
                        <el-rate
                          v-model="item.score"
                          disabled
                          text-color="#ff9900"
                        >
                        </el-rate>
                        <span
                          style="
                            position: absolute;
                            font-weight: 600;
                            font-size: 12px;
                            color: #ff9900;
                            left: 6.5rem;
                            top: 0.02rem;
                          "
                          v-show="item.reviewsNum"
                          >({{ item.reviewsNum }})</span
                        >
                      </div>
                    </span>
                    <span v-else>
                      <div style="background: #fff; height: 24px"></div>
                    </span>
                  </p>
                  <!-- <div
                    class="shop_link mobile"
                    @click="quickShop(item.id, item)"
                  >
                    <span
                      :class="
                        item.oversold == 1 && item.totalCount == 0
                          ? 'noAllow'
                          : ''
                      "
                      >QUICK SHOP</span
                    >
                  </div> -->
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="section-body">
            <a
              href="javascript:void(0)"
              class="view_all"
              style="margin-bottom: 26px;margin-top: 0px;"
              @click="viewAllProducts()">View all</a>
          </div>
        </div>
        <!-- <a
          href="javascript:void(0)"
          class="view_all"
          @click="viewAllCollections()"
          >View all</a
        > -->
      </div>
    </section>
    <section
      class="main__wrapper no-section-border best_seller__wrapper"
      v-show="brandShow"
    >
      <div class="section-header">
        <!--<h2 class="placeholder-glow">
          <span class="placeholder col-3">Shop by Brand</span>
        </h2>-->
      </div>
      <div class="section-body" style="border-top: 10px solid #f1f1f1; border-bottom: 10px solid #f1f1f1;">
        <div>
          <el-row
            :gutter="0"
            style="display: flex; flex-wrap: wrap; padding-bottom: 15px"
          >
            <el-col
              :span="6"
              :sm="6"
              :xs="12"
              v-for="item in brandList"
              :key="item.id"
              style="margin-bottom: 24px; margin-top: 10px"
            >
              <router-link
                tag="a"
                :to="{
                  name: 'ProductList',
                  query: {
                    type: 'brand',
                    brandId: item.id,
                    brandName: item.name,
                  },
                }"
              >
                <div
                  style="
                    cursor: pointer;
                    background: #fff;
                    height: 230px;
                    position: relative;
                  "
                >
                  <img
                    :src="item.img"
                    style="
                      display: block;
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      margin: auto;
                      width: 150px;
                      height: 150px;
                      object-fit: contain;
                    "
                  />
                  <p style="position: absolute; width: 100%; bottom: 20px;  text-align: center; font-weight: bold">{{ item.name }}</p>
                </div>
              </router-link>
            </el-col>
          </el-row>
        </div>
        <a href="javascript:void(0)" style="margin-bottom: 26px;margin-top: 0px;" class="view_all" @click="viewAllBrands()">View all</a>
      </div>
    </section>
    <!-- shop by category -->
    <!-- <section class="main__wrapper category__wrapper">
      <div class="section-header">
        <h2 class="placeholder-glow">
          <span class="placeholder col-2">{{ promotedCategory.name }}</span>
        </h2>
      </div>
      <div class="section-body">
        <el-row :gutter="28" style="display: flex; flex-wrap: wrap">
          <el-col
            :span="24"
            :md="12"
            v-for="item in promotedCategory.children"
            :key="item.id"
            style="margin-bottom: 26px"
          >
            <div class="category__item pr">
              <router-link
                tag="a"
                :to="{
                  name: 'ProductList',
                  query: {
                    type: 'category',
                    categoryId: item.id,
                    categoryName: item.name,
                  },
                }"
              >
                <img
                  :src="item.img"
                  class="img-fluid img-responsive"
                  :alt="item.name"
                />
                <p class="category__item_title">{{ item.name }}</p>
              </router-link>
            </div>
          </el-col>
        </el-row>
      </div>
    </section>  -->
    <!-- brand list -->
    <!-- <section
      v-show="Array.isArray(promotedBrands) && promotedBrands.length > 0"
      class="main__wrapper logo__wrapper"
    >
      <div class="logo-list">
        <div v-for="item in promotedBrands" :key="item.id">
          <router-link
            tag="a"
            :to="{
              name: 'ProductList',
              query: { type: 'brand', brandId: item.id, brandName: item.name },
            }"
          >
            <img :src="item.img" class="img-fluid" />
          </router-link>
        </div>
      </div>
    </section>  -->
    <!-- sale off -->
    <!-- <section class="main__wrapper sale_off">
      <div>
        <div class="category__item pr placeholder-glow">
          <router-link
            tag="a"
            :to="{
              name: 'ProductList',
              query: {
                type: 'collection',
                collectionId: saleCollection.id,
                collectionName: saleCollection.name,
              },
            }"
          >
            <img :src="saleCollection.img" class="img-fluid" />
            <div class="category__item_title">
              <p>{{ saleCollection.intro }}</p>
              <p class="promotion-block__cta">shop now</p>
            </div>
          </router-link>
        </div>
      </div>
    </section>  -->
    <!-- 主推产品 -->
    <!-- <section class="main__wrapper product_detail">
      <div class="div-default-mt" id="home-promoted-product">
        <product-order :product-info="promotedProduct"></product-order>
      </div>
    </section> -->
    <!-- More for our sale -->
    <!-- <section class="main__wrapper best_seller__wrapper">
      <div class="section-header">
        <h2>More for our sale</h2>
      </div>
      <div class="section-body">
        <div>
          <el-row :gutter="26" style="display: flex; flex-wrap: wrap">
            <el-col :span="12" :sm="8" v-for="item in products" :key="item.id">
              <div class="card">
                <div class="product_intro" v-quick-shop-hover>
                  <div class="product_tag" v-if="item.corner">
                    <span
                      class="sold_out"
                      :style="{ 'background-color': item.cornerColor }"
                      >{{ item.corner }}</span
                    >
                  </div>

                  <router-link
                    tag="a"
                    :to="{
                      name: 'ProductDetail',
                      query: {
                        type: 'product',
                        productId: item.id,
                        productName: item.name,
                      },
                    }"
                  >
                    <img :src="item.coverImg" class="card-img-top" />
                  </router-link>
                  <div
                    :class="
                      item.oversold == 1 && item.totalCount == 0
                        ? 'soldOut'
                        : 'allowNone'
                    "
                  >
                    SOLD OUT
                  </div>
                  <div class="shop_link" @click="quickShop(item.id, item)">
                    <span
                      :class="
                        item.oversold == 1 && item.totalCount == 0
                          ? 'noAllow'
                          : ''
                      "
                      >QUICK SHOP</span
                    >
                  </div>
                </div>
                <div class="card-body pr">
                  <p class="product-item__title">{{ item.name }}</p>
                  <p class="product-item__price" v-if="item.originalPrice">
                    <span class="cur_price">now <CurrencySymbol :symbol="item.symbol" />{{ item.sellingPrice | moneyFormat }}</span>
                    <span class="previous_price"
                      ><CurrencySymbol :symbol="item.symbol" />{{ item.originalPrice | moneyFormat }}</span
                    >
                  </p>
                  <p class="product-item__price" v-else>
                    <span><CurrencySymbol :symbol="item.symbol" />{{ item.sellingPrice | moneyFormat }}</span>
                  </p>
                  <div
                    class="shop_link mobile"
                    @click="quickShop4(item.id, item)"
                  >
                    <span
                      :class="
                        item.oversold == 1 && item.totalCount == 0
                          ? 'noAllow'
                          : ''
                      "
                      >QUICK SHOP</span
                    >
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <a href="javascript:void(0)" @click="viewAllProducts()" class="view_all"
          >View all</a
        >
      </div>
    </section> -->
    <!-- local pickup -->
    <!-- <section class="main__wrapper pickup">
      <div class="pxs-map">
        <div class="pxs-map-card-wrapper">
          <div class="pxs-map-card">
            <h2 class="pxs-map-card-heading">Local Pickup</h2>
            <div class="pxs-map-card-content">
              <p>
                We are working to fulfill all orders and are offering safe pickup from Monday-Friday!
                <br/>
                <br/>
                Please
                <router-link tag="a" class="contact-link" to="/contact">contact us</router-link>
                with any questions.
              </p>
            </div>
            <h2 class="pxs-map-card-heading">Opening Hours</h2>
            <div class="pxs-map-card-content text-left">
              <p><strong>Monday - Thursday:</strong> 10am - 6pm</p>
              <p><strong>Friday: </strong>10am - 9pm</p>
              <p><strong>Saturday: </strong>11am - 5pm</p>
              <p><strong>Sunday: </strong>11am - 5pm</p>
              <br />
              <p>
                Please
                <router-link tag="a" class="contact-link" to="/contact"
                  >contact us</router-link
                >
                with any questions.
              </p>
            </div>
          </div>
        </div>
        <div class="pxs-map-wrapper pxs-map-wrapper-height-medium"></div>
      </div>
    </section>  -->
    
    <section class="main__wrapper no-section-border best_seller__wrapper">
      <tight></tight>
    </section>
    <!-- join -->
    <join></join>
    <!--tight-->

    <quick-shop :product-id="productId" ref="quickShop"></quick-shop>

    <el-image-viewer v-if="viewImgVisible" :on-close="closeImgViewer" :url-list="viewImgList" :initialIndex="viewIndex" :zIndex="5000"/>
  </div>
</template>
<script>
import StarRating from 'vue-star-rating'
import { Carousel, Slide } from 'vue-carousel'
import { VueEllipsis } from 'vue-ellipsis-component'
import Join from '@/components/Join'
import QuickShop from '@/components/QuickShop'
import ProductOrder from '@/components/ProductOrder'
import Tight from '@/components/Tight'
import service from '@/utils/request'
import MobileNav from '@/layout/components/mobile-nav.vue'
import MainProduct from './home/MainProduct'
import ImgSwiper from '../components/img-swiper'
import PicturePreviewVue from 'picture-preview-vue'


export default {
  name: 'Home',
  metaInfo: {
    title: 'Luxury Fashion Bags Online Store - aFaler',
    meta: [
      {
        name: 'keywords',
        content: 'Luxury Fashion Bags | Women\'s Bags | Classic Tote Bags | Trendy Crossbody Bags | High-Value Fashion Bags | Bag Discounts | Bag Promotion Events | Online Store - aFaler',
      },
      {
        name: 'description',
        content: 'Online store specializing in high-end bags with a wide range of trendy styles. Enjoy occasional discounts and shop now!',
      },
    ],
    link: [
      {
        // 设置link
        rel: 'asstes',
        href: 'https://www.afaler.com',
      },
    ],
  },
  components: {
    Join,
    Tight,
    QuickShop,
    ProductOrder,
    StarRating,
    Carousel,
    Slide,
    VueEllipsis,
    MobileNav,
    MainProduct,
    ImgSwiper,
  },
  data() {
    return {
      loading: false,
      bannerList: [],
      commentList: [],
      zero: '0',
      promotedCollection: {},
      promotedCategory: {},
      promotedBrands: [],
      saleCollection: {},
      promotedProduct: {},
      products: [],
      Category4: [],
      productId: '',
      collectionsList: [],
      hotProducts: {},
      brandList: [],
      brandShow: true,
      topShow: true,

      viewImgVisible: false,
      viewImgList: [],
      viewIndex: 0,
    }
  },
  computed: {
    carouselHeight() {
      const clientWidth = document.body.clientWidth
      //return clientWidth <= 576 ? '500px' : '600px'
      return clientWidth <= 576 ? '220px' : '810px'
    },
    perpage() {
      const clientWidth = document.body.clientWidth
      return clientWidth <= 576 ? 1 : 3
    },
  },
  created() {
    this.getBannerList()
    //this.getHomepageData() //旧版首页接口
    this.getComments()
    this.getHomeCategorys()
    this.getCollections()
    this.getHotProducts()
    this.getBrandsList()
    this.setFbclid();
  },
  methods: {
    openMobileNav() {
      this.$refs.mobileNav.open()
    },
    viewDetail(item) {
      this.$router
        .push({
          name: 'ProductList',
          query: {
            type: 'category',
            categoryId: item.id,
            categoryName: item.name,
          },
        })
        .catch(() => {})
    },
    getBannerList() {
      service({
        url: '/banner',
        method: 'get',
      }).then((res) => {
        this.bannerList = res.data
      })
    },

    //首页导购
    getHomeCategorys() {
      this.loading = true
      service({
        url: '/open/categorys',
        type: 'get',
      }).then((res) => {
        //res.data = this.categorys
        //console.log(this.categorys)
        this.loading = false
        const { Category4 } = res.data
        this.Category4 = res.data
        //console.log(this.Category4)
      })
    },
    getCollections() {
      service({
        url: 'open/collections',
        method: 'get',
      }).then((res) => {
        //console.log(res.data)
        this.collectionsList = res.data
        // this.collectionsInfo = res.data.info
      })
    },
    //品牌列表
    getBrandsList() {
      service({
        url: 'open/brands',
        method: 'get',
      }).then((res) => {
        //console.log(res.data)
        this.brandList = res.data || []
        if (this.brandList.length == 0) {
          this.brandShow = false
        }
      })
    },
    //首页热销
    getHotProducts() {
      service({
        url: 'open/products',
        method: 'get',
      }).then((res) => {
        this.hotProducts = res.data || []
        //console.log(this.hotProducts)
        if (this.hotProducts.length == 0) {
          this.topShow = false
        }
      })
    },
    getHomepageData() {
      this.loading = true
      service({
        url: '/open/homepage',
        type: 'get',
      }).then((res) => {
        this.loading = false
        const {
          products,
          promotedBrands,
          promotedCategory,
          promotedCollection,
          promotedProduct,
          saleCollection,
        } = res.data
        this.promotedCollection = promotedCollection
        this.promotedCategory = promotedCategory
        this.promotedBrands = promotedBrands
        this.saleCollection = saleCollection
        this.promotedProduct = promotedProduct
        this.products = products
        // console.log(res.data)
      })
    },
    getComments(page = 1, limit = 10) {
      service({
        url: `/review/mall?page=${page}&limit=${limit}`,
        method: 'get',
      }).then((res) => {
        this.commentList = res.data
      })
    },
    quickShop(id, item) {
      // if (item && item.oversold == 1 && item.totalCount == 0) return
      this.productId = id
      this.$refs.quickShop.open()
    },
    viewAllCollections() {
      this.$router
        .push({
          name: 'ProductList',
          query: {
            type: 'collection',
            collectionId: this.promotedCollection.id,
            collectionName: this.promotedCollection.name,
          },
        })
        .catch(() => {})
    },
    viewAllProducts() {
      this.$router
        .push({
          name: 'ProductList',
          query: {
            type: 'product',
            productName: 'All Products',
          },
        })
        .catch(() => {})
    },
    viewAllBrands() {
      this.$router
        .push({
          name: 'CatalogList',
          query: {
            type: 'All Brands',
          },
        })
        .catch(() => {})
    },

    clickSmallImage(imgUrl, index, list) {

      this.isPc = this.$utils.isPc();
      this.viewImgList = list.map(item => item.replace('_thumbnail', ''));
      this.viewIndex = index;

      if (this.isPc) {
        this.viewImgVisible = true
      } else {
        // 预览多张图片
        PicturePreviewVue({
          url: imgUrl.replace('_thumbnail', ''),
          urlList: this.viewImgList // 当前图片所在列表
        })
      }

    },

    closeImgViewer() {
      this.viewImgVisible = false;
    },
    setFbclid() {
      let fbclid = this.$route.query.fbclid;
      if (fbclid) {
        sessionStorage.setItem('fbclid', fbclid);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.el-col-xs-12 {
  margin-bottom: 0 !important;
}
.el-col-xs-6 {
  margin-bottom: 0 !important;
}

.colletions_wrapper {
  overflow: hidden;

  .big-img {

    .collections-box {
      height: auto;

      img {
        height: auto!important;
      }
    }
  }
}

.best_seller__wrapper {
  margin-top: 0 !important;
}
.section-header {
  margin-top: 0 !important;
}
.placeholder-glow {
  line-height: 60px;
  vertical-align: bottom;
  /*background: #f1f1f1;*/

  span {
    color: #333333;
    position: relative;
    top: 0px;
  }
}
section:not(.el-drawer__body) {
  margin-bottom: 0 !important;
}
.section-body {
  margin-bottom: 0 !important;
  margin-top: 0;
}
/*.product-item__title {
  font-weight: 100 !important;
  font-size: 14px !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  !*height: 36px;*!
  flex: 1;
  width: 90%;
  text-align: left;
  padding-left: 6px;
}*/

.product-item__price {
  text-align: left;
  padding-left: 8px;
}
.collections-box {
  height: 430px;
  position: relative;
  margin-bottom: 5px;
  border-right: 2px solid #fff;
}
.collections-box:last-of-type {
  border: 0;
}
.collections-box div {
  border: 1px solid #fff;
  margin: 0 auto;
  width: 150px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  color: #fff;
  position: absolute;
  top: 70%;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  font-size: 20px;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
}
.collections-box img {
  display: block;
  width: 100%;
  height: 430px;
}
.collections-box p {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.collections-box span {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 48%;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 70%;
}

.section-box {
  margin: 0 0px;
  /* display: flex;
 flex-wrap: wrap;
 box-sizing: border-box; */
}

.section-box-s {
  /*background: black;*/
  margin: 0 0 1px 0;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;

  p {
    margin-top: 1rem;
    text-align: center;
    font-size: 18px;
    color: #131416;
    font-weight: bold;
    /* background: black; */
  }

  img {
    display: block;
    width: 11rem;
    height: 11rem;
    border-radius: 14rem;
  }
}


.main__wrapper {
  max-width: 100% !important;
}
.carousel-item {
  position: relative;
}
.carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}
.noAllow {
  cursor: not-allowed !important;
}
.soldOut {
  position: absolute;
  font-size: 0.75rem;
  color: rgba(249, 40, 8, 0.88);
  top: 0.5rem;
  right: 0.5rem;
}
.allowNone {
  display: none;
}
.menuBtn {
  display: block;
  position: fixed;
  /* top: 0px; */
  right: 30px;
  bottom: 100px;
  z-index: 100000;
}
.menuBtn img {
  display: block;
  width: 40px;
  height: 40px;
}
@media screen and (min-width: 576px) {
  .menuBtn {
    display: none;
  }
}
.product-item__price ::v-deep .el-rate__icon {
  font-size: 14px !important;
}

@media screen and (min-width: 576px) {
  .collections-box div {
    border: 1px solid #fff;
    margin: 0 auto;
    width: 200px;
    text-align: center;
    height: 60px;
    line-height: 60px;
    color: #fff;
    font-size: 24px;
    font-weight: 600 !important;
    border-radius: 30px;
    cursor: pointer;
    position: absolute;
    top: 70%;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .collections-box img {
    display: block;
    width: 100%;
    height: 430px !important;
  }
  .collections-box p {
    color: #fff;
    font-weight: 600;
    font-size: 28px !important;
    text-align: center;
    position: absolute;
    top: 45%;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .collections-box span {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    position: absolute;
    top: 55%;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 70%;
  }
}

@media screen and (max-width: 576px) {
  .d-block {
    height: 240px;
  }

  .big-img {

    .collections-box {

      p {
        top: 20%;
      }

      span {
        top: 32%;
        line-height: 1.1;
        font-size: 14px!important;
      }
    }
  }

  .collections-box div {
    border: 1px solid #fff;
    margin: 0 auto;
    width: 120px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #fff;
    position: absolute;
    top: 60%;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    font-size: 18px;
    font-weight: 600;
    border-radius: 30px;
    cursor: pointer;
  }
  .section-box-s img {
    display: block;
    float: right;
    width: 50% !important;
    height: 100%;

    border-radius: 100px;
    /*border-top-left-radius: 45px !important;*/
    /*border-bottom-left-radius: 45px !important;*/
  }
  .carousel-caption h3 {
    font-size: 26px !important;
  }
  .carousel-caption {
    width: 90% !important;
  }
}

@media screen and (max-width: 960px) {

  .section-box-s {
    margin: 0 2px 2px 0;
    box-sizing: border-box;
    cursor: pointer;

    p {
      width: 100%;
      float: left;
      font-size: 16px;
      font-weight: bold;
    }

    img {
      display: block;
      float: right;
      width: 50%;
      height: 100%;
    }
  }

  .section-box {
    display: block;
    margin: 0;
  }
  .section-box:nth-child(even) {
    margin-right: 0;
  }
}

.thumbnails {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  .thumbnail-image {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 18%;
    padding-top: 18%;
    margin-right: 2%;

    position:relative;

    :hover > img,
    .active > img {
      opacity: 0.6;
      box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.5);
    }

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 250ms;
    }
  }
}

</style>
